import { ImageDataLike } from "gatsby-plugin-image";
import { ImagesList } from "react-spring-lightbox/dist/types/ImagesList";

export const transformLightboxImages = (
  images: { id: string; image: ImageDataLike; fixedImage: ImageDataLike }[]
): ImagesList =>
  images.map((child) => ({
    src: (child.fixedImage?.images as any).fallback.src as string,
    loading: "lazy",
    alt: "",
  }));
