import React, { FC, useState } from "react";
import styled from "styled-components";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { cssEaseOutExpo } from "@theme/easings";
import { Icon } from "@iconify/react";
import { arrowForwardIcon } from "@assets/icons";
import Lightbox from "@components/atoms/Lightbox/Lightbox";
import { transformLightboxImages } from "@utils/transformLightboxImages";

SwiperCore.use([Navigation]);

const StyledWrapper = styled.section`
  margin: 180px auto 0;
  width: 100%;
  max-width: 1920px;

  @media (max-width: 1024px) {
    max-width: 700px;
    width: 90%;
    margin: 120px auto 0;
  }

  @media (max-width: 580px) {
    margin: 80px auto 0;
  }
`;

const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledHeadline = styled.h2`
  font-size: 4.4rem;
  font-weight: 700;
  margin: 10px 0 0;
  color: ${({ theme }) => theme.primary};
  text-align: center;

  @media (max-width: 580px) {
    font-size: 3.2rem;
  }
`;

const StyledSubHeadline = styled.h3`
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 600;
  order: -1;
  margin: 0;

  @media (max-width: 580px) {
    font-size: 1.8rem;
  }
`;

const StyledParagraph = styled.p`
  margin: 18px 0 0;
`;

const StyledSwiperWrapper = styled.div`
  margin-top: 60px;
  width: 100%;
  position: relative;
  overflow: hidden;

  @media (max-width: 580px) {
    margin-top: 40px;
  }

  .swiper {
    max-width: 100%;
    overflow: hidden;
    position: relative;

    @media (max-width: 1024px) {
      width: 100%;
      height: 54vw;
      max-width: unset;
      max-height: 400px;
    }
  }

  .swiper-wrapper {
    display: inline-flex;
    align-items: center;
  }

  .swiper-slide {
    @media (min-width: 1025px) {
      opacity: 0.2;
      transform: scale(0.8);
    }

    transition: opacity 1s ${cssEaseOutExpo}, transform 1s ${cssEaseOutExpo};
  }

  .swiper-slide-active {
    opacity: 1;
    transform: scale(1);
  }
`;

const StyledImage = styled(GatsbyImage)`
  width: 50vw;
  max-width: 960px;
  height: 32vw;
  max-height: 630px;
  border-radius: 32px;
  cursor: pointer;

  @media (max-width: 1024px) {
    width: 100% !important;
    height: 54vw !important;
    max-height: 400px;
  }
`;

const StyledArrowsWrapper = styled.div`
  margin-top: 70px;
  display: flex;
  justify-content: center;

  @media (max-width: 1024px) {
    margin-top: 40px;
  }

  @media (max-width: 580px) {
    margin-top: 20px;
  }
`;

const StyledArrowButton = styled.button`
  border: 0;
  background: transparent;
  font-size: 5rem;
  padding: 10px;
  color: ${({ theme }) => theme.primary};
  cursor: pointer;

  :active {
    transform: scale(0.9);
  }

  &#next-button svg {
    transform: rotate(180deg) !important;
  }

  @media (max-width: 580px) {
    font-size: 4rem;
  }
`;

const Gallery: FC<Props> = ({ images }) => {
  const [swiper, setSwiper] = useState<SwiperCore>(null);
  const [isLightboxActive, setLightboxActive] = useState<boolean>(false);
  const [currentImage, setCurrentImage] = useState<number>(0);

  const slideTo = (index: number) => swiper?.slideToLoop(index, 1000);

  const handleImageClick = (index: number) => {
    setCurrentImage(index);
    setLightboxActive(true);
  };

  const onSlideChange = (swiper: SwiperCore) => {
    setCurrentImage(swiper.realIndex);
  };

  return (
    <StyledWrapper data-scroll-section="">
      <StyledHeader data-scroll="1" data-scroll-speed="1">
        <StyledHeadline>Galeria wykonanej realizacji</StyledHeadline>
        <StyledSubHeadline>Galeria zdjęć</StyledSubHeadline>
        <StyledParagraph>Wizualizacje i zdjęcia inwestycji.</StyledParagraph>
      </StyledHeader>

      <StyledSwiperWrapper data-scroll="1" data-scroll-speed="1">
        <Swiper
          slidesPerView={1}
          breakpoints={{
            1025: {
              slidesPerView: "auto",
              centeredSlides: true,
            },
          }}
          grabCursor
          loop
          loopedSlides={images.length}
          spaceBetween={40}
          navigation={{
            prevEl: "#prev-button",
            nextEl: "#next-button",
          }}
          onSwiper={setSwiper}
          onSlideChange={onSlideChange}
          updateOnWindowResize
        >
          {images.map(({ fixedImage, id, alt }, index) => (
            <SwiperSlide key={id}>
              <StyledImage
                image={getImage(fixedImage)}
                alt={alt}
                onClick={() => handleImageClick(index)}
              />
            </SwiperSlide>
          ))}
        </Swiper>

        <StyledArrowsWrapper>
          <StyledArrowButton id="prev-button">
            <Icon icon={arrowForwardIcon} />
          </StyledArrowButton>
          <StyledArrowButton id="next-button">
            <Icon icon={arrowForwardIcon} />
          </StyledArrowButton>
        </StyledArrowsWrapper>
      </StyledSwiperWrapper>

      <Lightbox
        currentImageIndex={currentImage}
        setCurrentImageIndex={setCurrentImage}
        isOpen={isLightboxActive}
        setOpen={setLightboxActive}
        images={transformLightboxImages(images)}
        slideTo={slideTo}
      />
    </StyledWrapper>
  );
};

interface Props {
  images: {
    id: string;
    image: ImageDataLike;
    fixedImage: ImageDataLike;
    alt: string;
  }[];
}

export default Gallery;
