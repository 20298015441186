import React, { FC } from "react";
import styled from "styled-components";
import BasicTemplate from "@templates/BasicTemplate";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import Contact from "@components/molecules/Contact/Contact";
import { arrowForwardIcon } from "@assets/icons";
import { Icon } from "@iconify/react";
import { withLineHoverEffect } from "@theme/withLineHoverEffect";
import Gallery from "@components/molecules/Gallery/Gallery";
import { decodeHtml } from "@utils/decodeHtml";

const StyledHero = styled.div`
  height: 70vh;
  width: 100%;
  z-index: 1;
  position: relative;
  padding-top: 90px;

  @media (max-width: 580px) {
    height: 60vh;
  }
`;

const StyledHeroInnerWrapper = styled.div`
  display: flex;
  width: 90%;
  max-width: 1700px;
  margin: auto;
  padding: 75px 0;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;

  @media (max-width: 1024px) {
    align-items: center;
    justify-content: center;
  }
`;

const StyledHeadline = styled.h2`
  margin: 10px 0 0;
  font-weight: 700;
  font-size: 5.6rem;

  @media (max-width: 1024px) {
    text-align: center;
  }

  @media (max-width: 580px) {
    font-size: 3.8rem;
  }
`;

const StyledLocation = styled.p`
  color: ${({ theme }) => theme.primary};
  text-transform: uppercase;
  font-size: 2.2rem;
  font-weight: 600;
  margin: 0;

  @media (max-width: 580px) {
    font-size: 1.8rem;
  }
`;

const StyledHeroImageWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const StyledHeroImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`;

const StyledHeroImageOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.4);
  z-index: 1;
`;

const StyledContentWrapper = styled.section`
  margin: 130px auto 0;
  max-width: 1500px;
  width: 90%;

  @media (max-width: 1024px) {
    max-width: 700px;
    margin: 80px auto 0;
  }

  @media (max-width: 580px) {
    margin: 40px auto 0;
  }
`;

const StyledBackButton = styled(Link)`
  font-weight: 500;
  font-size: 2rem;
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.foreground};
  text-decoration: none;
  ${withLineHoverEffect()};

  svg {
    margin-right: 15px;
    font-size: 3rem;
  }

  @media (max-width: 580px) {
    font-size: 1.8rem;
  }
`;

const StyledContentInnerWrapper = styled.div`
  width: 100%;
  max-width: 1250px;
  margin: 75px auto 0;
  display: flex;
  align-items: center;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  @media (max-width: 580px) {
    margin: 50px auto 0;
  }
`;

const StyledContentHeadline = styled.h2`
  font-size: 4.8rem;
  line-height: 1.5;
  max-width: 500px;
  margin: 0 90px 0 0;

  @media (max-width: 1024px) {
    max-width: 100%;
    text-align: center;
    margin: 0;
    font-size: 3.8rem;
  }

  @media (max-width: 580px) {
    font-size: 2.8rem;
  }
`;

const StyledDescriptionWrapper = styled.div`
  line-height: 1.6;
  flex: 1;

  a {
    color: ${({ theme }) => theme.primary};
  }

  @media (max-width: 1024px) {
    margin-top: 20px;
  }

  @media (max-width: 580px) {
    p {
      text-align: justify;
    }
  }
`;

const TemporaryInvestment: FC<Props> = ({
  data: {
    directus: {
      investment_by_id: investment,
      settings,
      ogImage: { gallery: ogImage },
    },
  },
}) => (
  <BasicTemplate
    title={settings.website_title}
    subpageTitle={investment.name}
    description={decodeHtml(
      investment.description.replace(/<[^>]*>?/gm, "").slice(0, 255)
    )}
    ogImage={ogImage[0].directus_files_id.imageFile.childImageSharp.fixed.src}
    renderHero={() => (
      <StyledHero data-scroll-section="">
        <StyledHeroInnerWrapper data-scroll="" data-scroll-speed="1">
          <StyledHeadline>{investment.name}</StyledHeadline>
          <StyledLocation>{investment.location}</StyledLocation>
        </StyledHeroInnerWrapper>
        <StyledHeroImageWrapper>
          <StyledHeroImageOverlay />
          <StyledHeroImage
            image={getImage(
              investment.gallery[0].directus_files_id.imageFile.image
                .gatsbyImageData
            )}
            alt={investment.name}
          />
        </StyledHeroImageWrapper>
      </StyledHero>
    )}
  >
    <StyledContentWrapper data-scroll-section="">
      <StyledBackButton
        to="/"
        data-scroll=""
        data-scroll-speed="1"
        state={{ scrollTo: `#on_sale` }}
      >
        <Icon icon={arrowForwardIcon} /> Powrót
      </StyledBackButton>

      <StyledContentInnerWrapper>
        <StyledContentHeadline data-scroll="" data-scroll-speed="1">
          {investment.title}
        </StyledContentHeadline>
        <StyledDescriptionWrapper
          data-scroll=""
          data-scroll-speed="1"
          dangerouslySetInnerHTML={{ __html: investment.description }}
        />
      </StyledContentInnerWrapper>
    </StyledContentWrapper>
    <Gallery
      images={investment.gallery.map(({ directus_files_id }) => ({
        id: directus_files_id.id,
        image: directus_files_id.imageFile.image.gatsbyImageData,
        fixedImage: directus_files_id.imageFile.fixedImage.gatsbyImageData,
        alt: investment.name,
      }))}
    />
    <Contact />
  </BasicTemplate>
);

interface Props {
  data: {
    directus: {
      settings: {
        website_title: string;
      };
      investment_by_id: {
        description: string;
        name: string;
        title: string;
        location: string;
        gallery: {
          directus_files_id: {
            id: string;

            imageFile: {
              image: {
                gatsbyImageData: ImageDataLike;
              };
              fixedImage: {
                gatsbyImageData: ImageDataLike;
              };
            };
          };
        }[];
      };
      ogImage: {
        gallery: {
          directus_files_id: {
            imageFile: {
              childImageSharp: {
                fixed: {
                  src: string;
                };
              };
            };
          };
        }[];
      };
    };
  };
}

export const query = graphql`
  query ($id: ID!) {
    directus {
      settings {
        website_title
      }
      ogImage: investment_by_id(id: $id) {
        gallery(limit: 1) {
          directus_files_id {
            id
            imageFile {
              childImageSharp {
                fixed(quality: 70, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }
      }
      investment_by_id(id: $id) {
        description
        name
        title
        location
        gallery {
          directus_files_id {
            id
            imageFile {
              image: childImageSharp {
                gatsbyImageData(
                  quality: 80
                  layout: FULL_WIDTH
                  placeholder: DOMINANT_COLOR
                )
              }
              fixedImage: childImageSharp {
                gatsbyImageData(quality: 100, layout: FIXED, formats: [JPG])
              }
            }
          }
        }
      }
    }
  }
`;

export default TemporaryInvestment;
